import http from '@/http'

export default {
    namespaced: true,
    actions: {
        async getHistory(context, { chatbot_id, conversation_id }) {
            return await http.get(
                `/api/chat/history?chatbot_id=${chatbot_id}&conversation_id=${conversation_id}`
            )
        },
    },
}
