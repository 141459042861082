import http from '@/http'

export default {
    namespaced: true,
    actions: {
        async getChatbotAppearance(context, chatbotId) {
            return await http.get(`/api/chatbot/${chatbotId}/appearance`)
        },
    },
}
