import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/widget/trigger',
        component: () => import('@/views/widget/ChatWidgetTrigger.vue'),
    },
    {
        path: '/widget/window',
        component: () => import('@/views/widget/ChatWidgetWindow.vue'),
    },
    {
        path: '/iframe',
        component: () => import('@/views/iframe/ChatWidgetIframe.vue'),
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router
