import Vue from 'vue'
import store from './store'
import router from './router'
import 'document-register-element/build/document-register-element'
import './assets/css/main.css'
import App from '@/App.vue'

Vue.config.productionTip = false

new Vue({
    router,
    store,
    beforeCreate() {
        this.$store.dispatch('session/initialiseStore')
    },
    render: (h) => h(App),
}).$mount('#app')
