import axios from 'axios'

const http = axios.create({
    baseURL: process.env.VUE_APP_BACKEND,
    headers: {
        'Content-Type': 'application/json',
    },
})

export default http
