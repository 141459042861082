import { v4 as uuidv4 } from 'uuid'

export default {
    namespaced: true,
    state: {
        data: {
            chatbots: [],
        },
    },
    actions: {
        initialiseStore(context) {
            if (localStorage.getItem('ai_chatbot_widget')) {
                context.commit('DATA_UPDATE', {
                    data: JSON.parse(localStorage.getItem('ai_chatbot_widget')),
                })
            }
        },
        loadConversation(context, chatbotId) {
            if (context.state.data.chatbots && context.state.data.chatbots) {
                let chatbot = context.state.data.chatbots.find(
                    (chatbot) => chatbot.chatbotId === chatbotId
                )
                if (chatbot) {
                    if (!chatbot.conversationId) {
                        chatbot.conversationId = uuidv4()
                        context.commit('DATA_UPDATE', {
                            data: context.state.data,
                        })
                    }
                    return chatbot.conversationId
                }
            }
            let chatbot = {
                chatbotId: chatbotId,
                conversationId: uuidv4(),
            }
            context.state.data.chatbots.push(chatbot)
            context.commit('DATA_UPDATE', { data: context.state.data })
            return chatbot.conversationId
        },
        clearConversation(context, chatbotId) {
            if (context.state.data.chatbots && context.state.data.chatbots) {
                let chatbot = context.state.data.chatbots.find(
                    (chatbot) => chatbot.chatbotId === chatbotId
                )
                if (chatbot && chatbot.conversationId) {
                    chatbot.conversationId = null
                    context.commit('DATA_UPDATE', { data: context.state.data })
                }
            }
        },
    },
    mutations: {
        DATA_UPDATE(state, { data }) {
            state.data = data
            localStorage.setItem(
                'ai_chatbot_widget',
                JSON.stringify(state.data)
            )
        },
    },
    getters: {
        getConversationId(state) {
            return (chatbotId) => {
                if (state.data.chatbots && state.data.chatbots) {
                    let chatbot = state.data.chatbots.find(
                        (chatbot) => chatbot.chatbotId === chatbotId
                    )
                    if (chatbot) {
                        return chatbot.conversationId
                    }
                }
            }
        },
    },
}
